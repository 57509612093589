<template>
  <div class="addanswer">
    <!-- <el-button href="#answers" @click="$router.push('answers')">查看答题情况</el-button> -->
    <el-container style="height: 100%; border: 1px solid #eee">
      <el-container>
        <el-main>
          <div class="bigDiv">
            <el-form label-width="80px" :inline="true">
              <el-form-item label="考场名称:">
                <el-input v-model="room_id" placeholder="请输入考场名称" clearable></el-input>
              </el-form-item>
              <el-form-item label="形考时间:">
                <el-date-picker v-model="value" type="datetime" placeholder="选择形考日期时间">
                </el-date-picker>
              </el-form-item>
              <el-form-item label="最小时间:">
                <el-input v-model="value1" placeholder="请输入最小时间" clearable></el-input>
              </el-form-item>
              <el-form-item label="最大时间:">
                <el-input v-model="value2" placeholder="请输入最大时间" clearable></el-input>
              </el-form-item>
              <el-button type="primary" @click="goSearch">查询</el-button>
            </el-form>

            <el-table :data="lists" style="width: 100%" :stripe="true" :border="true"
              :header-cell-style="{background:'#eef1f6',color:'#606266'}">
              <el-table-column prop="tr_id" label="考场序号" width="90">
              </el-table-column>
              <el-table-column prop="tr_name" label="考场名">
              </el-table-column>
              <el-table-column prop="tr_human_rows" label="人数">
              </el-table-column>
              <!-- <el-table-column prop="c_course" label="科目名称">
              </el-table-column> -->
              <!-- <el-table-column prop="tr_name" label="考场名称">
              </el-table-column> -->

              <!-- <el-table-column prop="ar_grade" label="成绩">
              </el-table-column> -->
              <el-table-column prop="tr_interval_min" label="最小时间" width="80">
              </el-table-column>
              <el-table-column prop="tr_interval_max" label="最大时间" width="80">
              </el-table-column>
              <el-table-column prop="tr_start_time" label="形考时间" width="180">
                <template slot-scope="scope">
                  {{parseTime(scope.row.tr_start_time,'{y}-{m}-{d} {h}:{i}:{s}')}}
                </template>
              </el-table-column>
              <el-table-column prop="tr_comment" label="备注">
              </el-table-column>
              <div slot="empty">
                <p>
                  <img :src="imgUrl" />
                </p>
              </div>
            </el-table>
            <el-pagination :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper"
              @current-change="handleCurrentChange" :page-sizes="[100, 200, 300, 400]"
              @size-change="handleSizeChange" :total="allLength || 0" align="right">
            </el-pagination>
          </div>
        </el-main>
        <!-- 查看详情 -->
        <!-- <el-dialog title="题库详情" :visible.sync="showmessage" showCancelButton=true
          showConfirmButton=true>
          <el-form :model="ruleForm" ref="ruleForm" label-width="100px" class="demo-ruleForm">
            <el-form-item label="活动名称">
              <el-input v-model="ruleForm.name"></el-input>
            </el-form-item>
            <el-form-item label="活动时间">
              <el-col :span="11">
                <el-date-picker type="date" placeholder="选择日期" v-model="ruleForm.date1"
                  style="width: 100%;"></el-date-picker>
              </el-col>
              <el-col class="line" :span="2">-</el-col>
              <el-col :span="11">
                <el-time-picker placeholder="选择时间" v-model="ruleForm.date2" style="width: 100%;">
                </el-time-picker>
              </el-col>
            </el-form-item>
          </el-form>
          <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="showmessage = false">上 传 答 案</el-button>
          </span>
        </el-dialog> -->
      </el-container>

    </el-container>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import { parseTime } from '@/common/date.js' // 在组件中引用date.js,时间戳
import commonApi from '@/api/commonApi.js'

export default {
  data () {
    // name: 'Home'
    return {
      tableHeight: window.innerHeight - 300,
      ruleForm: {
        name: '',
        region: '',
        date1: '',
        date2: '',
        delivery: false,
        type: [],
        resource: '',
        desc: ''
      },
      realname: '', // 姓名
      username: '', // 学号
      cname: [], // 科目名称
      cid: '', // 选中的科目名称
      room_id: '', // 选中的考场
      tname: [], // 根据科目名称查询任务
      sc_task: '', // 选中的任务名称
      fileList: [], // 存放文件的数组
      showmessage: false, // 查看弹窗
      labelPosition: 'right',
      formLabelAlign: {
        sc_realname: '',
        sc_username: '',
        sc_answer_status: '',
        c_name: ''
      },
      dstatus: [{
        name: '单选题',
        answerStatus: 0
      }, {
        name: '多选题',
        answerStatus: 1
      }, {
        name: '填空题',
        answerStatus: 2
      }, {
        name: '附件题',
        answerStatus: -1
      }], // 答题状态
      answerStatus: '', // 选中的答题状态
      lists: [], // 获取到的数据
      indexPage: 1, // 当前页数，默认第一页
      pageSize: 100, // 每页显示条数
      allLength: 0, // 查询到的数据总条数
      allPageNumber: 0, // 查询到的数据总页数
      imgUrl: require('../assets/Lark20201216-174717.png'), // 图片地址
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近一个月',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近三个月',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
            picker.$emit('pick', [start, end])
          }
        }]
      },
      value: '',
      value1: '',
      value2: ''
    }
  },
  mounted () {
    this.allCourseName()
    this.goSearch()
  },
  methods: {
    // 查看信息
    handleShow: function (index, rows) {
      this.showmessage = true
      this.formLabelAlign = rows
    },
    // 删除题库
    handleDelete: function (index, rows) {
    },
    // 导入题库exl表
    handleChange (file, fileList) {
      this.fileList = fileList.slice(-3)
    },
    // 侧边栏展开
    handleOpen: function (key, keyPath) {
    },
    // 侧边栏关闭
    handleClose: function (key, keyPath) {
    },
    // 查询科目名称
    allCourseName: function () {
      commonApi.selectCourse().then((response) => {
        this.cname = response
      }).catch((response) => {
        console.log(response)
      })
    },
    // 查询数据
    goSearch: function () {
      const submitTime = new Date(this.value).getTime()

      const data = {
        current: this.indexPage, // 当前页
        size: this.pageSize // 每页数据条数
      }
      // 科目的ID
      for (let j = 0; j < this.cname.length; j++) {
        if (this.cid === this.cname[j].ccourse) {
          data.courseId = this.cname[j].cid
        }
      }
      // 任务的ID
      for (let i = 0; i < this.tname.length; i++) {
        if (this.sc_task === this.tname[i].tt_task_name) {
          data.taskId = this.tname[i].tt_id
        }
      }
      // 学生学号
      if (this.realname) data.stuId = Number(this.realname)
      // 考场名称
      if (this.value1) data.intervalMin = this.value1
      if (this.value2) data.intervalMax = this.value2
      if (submitTime) data.startTime = submitTime
      if (this.room_id) data.trName = this.room_id
      // 调取查询接口
      commonApi.queryTestRecordPage(data).then((response) => {
        this.lists = response.records || []
        this.allLength = Number(response.total)
        this.allPageNumber = this.allLength / this.pageSize || 1
      }).catch((response) => {
        console.log(response)
      })
    },
    // 查看全部
    showAllinfo: function () {
      // 输入框清空
      this.value = ''
      this.value2 = ''
      this.value1 = ''
      this.cid = ''
      this.sc_task = ''
      this.room_id = ''
      this.realname = ''
      this.goSearch()
    },
    // 插入答题记录
    addAnswerinfo: function () {
      this.showmessage = true
    },
    // 页数切换
    handleCurrentChange (cpage) {
      this.indexPage = cpage
      this.goSearch()
    },
    // 每页条数切换
    handleSizeChange (val) {
      // 获取每页数据条数
      this.pageSize = val
      // 触发查询操作
      this.goSearch()
    },
    // 时间戳转化声明
    parseTime: parseTime
  },
  watch: {
    // 监听科目名称下拉选框切换时发送请求获取到任务名称,并且触发查询操作
    cid (val, oldVal) {
      this.sc_task = ''
      const data = {
        current: this.indexPage, // 当前页
        size: this.pageSize // 每页数据条数
      }
      for (let i = 0; i < this.cname.length; i++) {
        if (this.cid === this.cname[i].ccourse) {
          data.courseId = this.cname[i].cid
        }
      }
      commonApi.showTask(data).then((response) => {
        this.tname = response
      }).catch((response) => {
        console.log(response)
      })
      this.indexPage = 1
      this.allPageNumber = 0
      // 触发查询操作
      // this.goSearch()
    },

    // 监听答题状态改变时触发查询操作
    answerStatus (val, oldVal) {
      this.indexPage = 1
      this.allPageNumber = 0
      // 触发查询操作
      this.goSearch()
    },

    // 监听任务名称改变时触发查询操作
    sc_task (val, oldVal) {
      this.indexPage = 1
      this.allPageNumber = 0
      // 触发查询操作
      this.goSearch()
    }
  }
}
</script>

<style>
.el-header {
  background-color: #b3c0d1;
  color: #333;
  line-height: 60px;
}
.el-aside {
  color: #333;
}
.disrow {
  display: flex;
  flex-direction: row;
}
.timerow {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  color: #606266;
  white-space: nowrap;
  margin-left: 10px;
}
</style>
